import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../store';
import React, { useEffect, useMemo, useState } from 'react';
import AddUrlFields, { createNewValuesFromMap, initialDestTypeToValuesMap } from '../add-url-fields';
import { hasDuplicatesValueOnArray } from '../../../../../../../utils/util';
import { Button } from 'antd';
import { MAX_DEST_VALUES } from '../../../../../../../utils/constants';
import CustomModal from '../../../../../../shared/custom-modal/custom-modal';
import APNSelection from '../../apn-selection/apn-selection';
import DestTypeGroup from '../dest-type-group';

export interface ManualAddUrlIPProps {
    closeModal: () => void;
}

const ManualAddUrlIP = observer((props: ManualAddUrlIPProps) => {

    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const [destinationType, setDestinationType] = useState<'white' | 'black'>('white');
    const [destTypeToValuesMap, setDestTypeToValuesMap] = useState(initialDestTypeToValuesMap);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setSelectedValues([]);
        setDestinationType('white');
        setDestTypeToValuesMap(initialDestTypeToValuesMap);
    }, [isModalOpen]);

    const totalItemsInDestTypeToValues = useMemo(() => {
        return createNewValuesFromMap(destTypeToValuesMap)
    }, [destTypeToValuesMap]);

    const hasDuplicates = useMemo(() => {
        return hasDuplicatesValueOnArray(totalItemsInDestTypeToValues);
    }, [totalItemsInDestTypeToValues]);

    const handleOk = () => {
        useCaseStore.addApnList(selectedValues);
        selectedValues.forEach(apnName =>
            useCaseStore.initDestValues(apnName, [{ listType: destinationType, parameterValues: totalItemsInDestTypeToValues }]));
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        props.closeModal();
    };

    const okButtonProps = {
        disabled: !selectedValues.length || hasErrors || totalItemsInDestTypeToValues.length > MAX_DEST_VALUES
            || hasDuplicates || !totalItemsInDestTypeToValues.length};

    const addApnModal = () => {
        return (
            <CustomModal
                isOpen={isModalOpen}
                width={480}
                title="Assign APN"
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Assign"
                okButtonProps={okButtonProps}
                customClassName="manual-add-url"
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <APNSelection
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    mode="multiple"
                />
                <div className="add-url-wrapper">
                    <AddUrlFields
                        isDuplicate={hasDuplicates}
                        isMaxEntries={totalItemsInDestTypeToValues.length > MAX_DEST_VALUES}
                        onValuesChange={(newDestTypeToValuesMap) => {
                            setDestTypeToValuesMap((prevState) => ({ ...prevState, ...newDestTypeToValuesMap }));
                        }}
                        setHasErrors={setHasErrors}
                    />
                    <DestTypeGroup destinationType={destinationType} setDestinationType={setDestinationType} />
                </div>
            </CustomModal>
        );
    };

    return (
        <>
            <Button className={'secondary-btn'}
                    type="primary"
                    onClick={() => setIsModalOpen(true)}>
                + URL/IP
            </Button>
            {isModalOpen && addApnModal()}
        </>
    );
});

export default ManualAddUrlIP;