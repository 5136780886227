import React from 'react';
import ManualAddUrlIP from '../manual-add-url-ip/manual-add-url-ip';
import { ReactComponent as BaseModal } from '../../../../../../../assets/svg/basic-modal.svg';
import './assign-selection-panel.scss';
import { SelectDestinationModal } from '../select-destination-modal/select-destination-modal';

const AssignSelectionPanel = ({closeModal}) => {
    return (
        <div className="assign-selection-panel">
            <div className="assign-selection-panel-content">
                <BaseModal />
                <div className="assign-selection-panel-title">
                    To create a list of allowed or denied traffic destinations either select destinations from your APN
                    traffic,
                    <div className="assign-selection-panel-description">
                        or manually add URLs and IP addresses.
                    </div>
                </div>
            </div>
            <div className="assign-selection-panel-footer">
                <SelectDestinationModal mode="add" closeModal={closeModal} />
                <ManualAddUrlIP closeModal={closeModal} />
            </div>
        </div>
    );
};

export default AssignSelectionPanel;