import './exception-list.scss';
import { observer } from 'mobx-react-lite';
import { Button, Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../../../assets/svg/white_upload_16.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/blue_download_16.svg';
import { ReactComponent as HistoryIcon } from '../../../../assets/svg/history_16.svg';
import { useStores } from '../../../store';
import { Sidebar } from '../../../shared/sidebar/sidebar';
import { VersionHistorySidebar } from './version-history-sidebar';
import { saveCsvFile } from '../../../../utils/file-saver';
import moment from 'moment';
import { SettingsSubMenu } from '../../../../utils/enums';

export const ExceptionList = observer(() => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [inputKey, setInputKey] = useState(Date.now());
    const [selectedUseCase, setSelectedUseCase] = useState('all');
    const {useCaseStore, exceptionListStore, customerStore, userStore} = useStores();
    const displayValues = useCaseStore.useCaseDisplayValues;
    const fileInput = useRef<HTMLInputElement>();

    useEffect(() => {
        useCaseStore.setActiveSubMenu(SettingsSubMenu.EXCEPTIONS);
    }, []);

    const onUploadClick = () => {
        fileInput.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            await exceptionListStore.UploadExceptionListFile(getFileUploadRequest(file));
        }
        setInputKey(Date.now());
    };

    function getFileUploadRequest(file: any): FormData {
        const name = userStore.data.displayName.split(', ');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('user', JSON.stringify({email: userStore.data.email, firstName: name[1], lastName: name[0]}));
        formData.append('use_case', selectedUseCase);
        return formData;
    }

    async function onDownloadClick() {
        exceptionListStore.setIsLoading(true);
        await exceptionListStore.LoadExceptionListFile(customerStore.selectedCustomer.name, selectedUseCase, null);
        if (exceptionListStore.file) {
            const useCaseFileName = displayValues.get(selectedUseCase).displayName.toLowerCase().replaceAll(/[\s/]+/g, '_');
            await saveCsvFile(exceptionListStore.file, `${useCaseFileName}_${moment().format('MMDDYYYYHHmm')}.csv`);
        }
        exceptionListStore.setIsLoading(false);
    }

    async function onHistoryClick() {
        exceptionListStore.setIsLoading(true);
        await exceptionListStore.LoadExceptionList(customerStore.selectedCustomer.name, selectedUseCase);
        exceptionListStore.setIsLoading(false);
        setShowSidebar(true);
    }

    const onRadioChange = (e: RadioChangeEvent) => {
        setSelectedUseCase(e.target.value);
    };

    const shouldBeDisplayed = (displayValue: any) => {
        if (displayValue.displayName === 'All Anomalies') return true;

        const isRatTypeSupported = useCaseStore.isRatTypeSupportedByUseCaseName(displayValue.displayName, customerStore.selectedCustomer)
        return isRatTypeSupported && !displayValue.hideOnExceptionList;
    };

    return useCaseStore.data && (<div className='exception-list-container'>
        <div className='instructions'>To view the list of exceptions, download the current list.<br/>Importing a new list will override the current list.</div>
        <Radio.Group onChange={onRadioChange} value={selectedUseCase}>
            {Array.from(displayValues).map(([key]) => (
                shouldBeDisplayed(displayValues.get(key)) && <div key={key} className='exception-item' id={key}>
                    <Radio value={key}>{displayValues.get(key).displayName}</Radio>
                </div>
            ))}
        </Radio.Group>
        <div className='exception-buttons-container'>
            <input type="file" ref={fileInput} style={{display: 'none'}} onChange={handleFileChange} accept='.csv'
                   key={inputKey}/>
            <Button icon={<UploadIcon/>} type="primary" className={'download'}
                    onClick={onUploadClick}>Upload</Button>
            <Button icon={<DownloadIcon/>} type="primary" className={'secondary-btn'}
                    onClick={onDownloadClick}>Download</Button>
            <Button icon={<HistoryIcon/>} type="primary" className={'secondary-btn version-history'}
                    onClick={onHistoryClick}> Version history</Button>
        </div>
        <Sidebar sidebarComponent={<VersionHistorySidebar onClose={() => setShowSidebar(false)}
                                                          customerName={customerStore.selectedCustomer?.name}
                                                          exceptionListFiles={exceptionListStore.data}/>}
                 isOpen={showSidebar} setIsOpen={setShowSidebar} width={'264px'}/>
    </div>)
});
