import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { Button } from 'antd';
import './add-apn.scss';
import CustomModal from '../../../../../shared/custom-modal/custom-modal';
import AssignSelectionPanel from '../unauthorized-destination/assign-selection-panel/assign-selection-panel';
import APNSelection from '../apn-selection/apn-selection';

const AddApn = observer(() => {

    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useEffect(() => {
        setSelectedValues([]);
    }, [isModalOpen]);

    const handleOk = () => {
        useCaseStore.addApnList(selectedValues);
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addApnModal = () => {
        return (
            <CustomModal
                isOpen={isModalOpen}
                width={480}
                title={useCaseStore.isAuthorizedUseCase ? "Select Destination" : "Assign APN"}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Assign"
                customClassName="assign-apn-modal"
                okButtonProps={{ disabled: !selectedValues.length}}
                showFooter={!useCaseStore.isAuthorizedUseCase}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                {useCaseStore.isAuthorizedUseCase ?
                    <AssignSelectionPanel closeModal={handleCancel} /> :
                    <APNSelection
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        mode="multiple"
                    />
                }
            </CustomModal>
        );
    };

    return (
       <>
           <Button className={'add-apn-btn secondary-btn'}
               type="primary"
               onClick={() => setIsModalOpen(true)}
               disabled={!useCaseStore.currentUseCaseModel.table && !useCaseStore.isAuthorizedUseCase}>
           Assign APN</Button>
           {isModalOpen && addApnModal()}
       </>
    );
});

export default AddApn;
