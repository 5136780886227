import React from 'react';
import './nav-bar.scss';
import { useStores } from '../../../../store';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

export interface NavbarProps {
    disableInactive?: boolean;
    relativePath: string;
}

const Navbar = observer((props: NavbarProps) => {
    const { useCaseStore, customerStore } = useStores();

    function getItemClassName(item) {
        const isActive = useCaseStore.currentUseCaseModel.name === item.name ? 'active' : '';
        const isDisabled = props.disableInactive && !useCaseStore.isActiveByUseCaseName(item.name) ? 'disabled' : '';
        return `nav-item ${isActive} ${isDisabled}`.trim();
    }

    const shouldBeDisplayed = (useCase: any) => {
        const isRatTypeSupported = useCaseStore.isRatTypeSupportedByUseCaseName(useCase.name, customerStore.selectedCustomer);
        return isRatTypeSupported && !useCase.hideOnNavbar;
    }

    return (
        <div className="navbar-wrapper">
            <div className={'navbar'}>
                {useCaseStore.model?.usecases?.filter(item => shouldBeDisplayed(item)).map((item) => (
                    <Link
                        key={item.name}
                        to={`../${props.relativePath}/${item.name}`}
                        className={getItemClassName(item)}
                    >
                        {item.tabTitle}
                    </Link>
                ))}
            </div>
        </div>
    );
});

export default Navbar;