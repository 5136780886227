import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/svg/delete.svg';

export const DestinationList: React.FunctionComponent<{  type: 'black' | 'white', apnName: string }> = observer(({  type, apnName }) => {

    const { useCaseStore } = useStores();

    const sortedList =  useCaseStore.sortedDestValueByType(type, apnName);

    const deleteItem = (listType, valueIndex) => {
        const copyList = [...sortedList];
        copyList.splice(valueIndex, 1);
        useCaseStore.changeParamValueByApn(apnName, `${listType}_list_destinations`, copyList);
    };

    return <div className='destination-table'>
        <div className='destination-header'>
            <div className="dest-header-cell">Destination</div>
            <div className="dest-header-cell">Type</div>
        </div>
        <div className="dest-table-content">
            {sortedList?.map((parameter, index) => {
                return <div className='dest-row' key={`${apnName}-${index}-${type}`}>
                    <div className='dest-cell'>{parameter.value}</div>
                    <div className='dest-cell'>{parameter.name}</div>
                    <div className='delete-cell' onClick={() => deleteItem(type, index)}>
                        <DeleteIcon/>
                    </div>
                </div>;
            })}
        </div>
    </div>;
});
