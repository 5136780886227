import React, { useEffect, useState } from 'react';
import { Checkbox, Select } from 'antd';
import { useStores } from '../../../../../store';
import { observer } from 'mobx-react-lite';
import './apn-selection.scss';

interface APNSelectionProps {
    selectedValues: string[];
    setSelectedValues: (values: string[]) => void;
    mode?: 'multiple';
}

const APNSelection: React.FC<APNSelectionProps> = observer(({selectedValues, setSelectedValues, mode}) => {

    const { useCaseStore } = useStores();

    const [options, setOptions] = useState([]);
    const [filtersOptions, setFiltersOptions] = useState([]);

    useEffect(() => {
        const copy = [...useCaseStore.unSelectedApn];
        const copyList = copy.map(name => ({
            label: name,
            value: name,
        }));
        setOptions(copyList);
        setFiltersOptions(copyList);
    }, [useCaseStore.apnPreferences, useCaseStore.unSelectedApn]);

    const handleChange = (values: string | string[]) => {
        if (Array.isArray(values)) {
            setSelectedValues(values);
        } else {
            setSelectedValues([values]);
        }
    };

    const dropdownRender = (menu: React.ReactNode) => {
        if (mode === 'multiple') {
            return (
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {filtersOptions.map((option: any) => (
                        <div key={option.value} className="checkbox-option">
                            <Checkbox
                                checked={selectedValues.includes(option.value)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(mode === 'multiple' ? [...selectedValues, option.value] : [option.value]);
                                    } else {
                                        handleChange(selectedValues.filter((value) => value !== option.value));
                                    }
                                }}>
                                {option.label}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div>{menu}</div>;
        }
    };

    const onSearch = (searchText: string) => {
        setFiltersOptions(options.filter((option: any) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        ));
    };

    return (
        <>
            <div className="select-label">APN</div>
            <Select mode={mode}
                    showSearch={true}
                    onSearch={onSearch}
                    size={"large"}
                    popupClassName="dropdown-custom apn-selection"
                    dropdownRender={dropdownRender}
                    options={options}
                    value={selectedValues}
                    onChange={handleChange}
                    placeholder="Search APN..."
                    className="checkbox-select"
            />
        </>
    );
});

export default APNSelection;