import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../store';
import React, { useState } from 'react';
import './unauthorized-destination.scss';
import { DeleteModal } from '../delete-modal/delete-modal';
import { ApnItem } from './apn-item';
import { ReactComponent as BaseModal } from '../../../../../../assets/svg/basic-modal.svg';

export const UnauthorizedDestination = observer(() => {
    const { useCaseStore } = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeApn, setActiveApn] = useState<null | string>(null);

    return (
        <div className="unauthorized-destination">
            {useCaseStore.apnPreferences.length ? useCaseStore.apnPreferences.map((apnPref) => {
                return <ApnItem item={apnPref} key={apnPref.apnName} setActiveApn={setActiveApn} setIsModalOpen={setIsModalOpen} />;
            }) : <div className="no-apn-selected">
                <BaseModal />
                <div className="assign-selection-panel-title">
                    Create a list of allowed or denied traffic destinations for your Access Point Name (APN).
                </div>
                <div className="assign-selection-panel-description">
                    Select an APN and then select traffic destinations or add individual URLs and IP addresses.
                </div>
            </div>}

            {isModalOpen && <DeleteModal activeApn={activeApn} isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>}
        </div>
    );
});