import React from 'react';
import { Radio } from 'antd';

interface DestTypeGroupProps {
    destinationType: 'white' | 'black';
    setDestinationType: (type: 'white' | 'black') => void;
}

const DestTypeGroup: React.FC<DestTypeGroupProps> = ({ destinationType, setDestinationType }) => {
    return (
        <Radio.Group
            onChange={(e) => setDestinationType(e.target.value)}
            value={destinationType}>
            <Radio value={'white'}>Authorized</Radio>
            <Radio value={'black'}>Unauthorized</Radio>
        </Radio.Group>
    );
};

export default DestTypeGroup;