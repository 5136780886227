import React, { useMemo, useState } from 'react';
import CustomModal from '../../../../../../shared/custom-modal/custom-modal';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../../store';
import AddUrlFields, { createNewValuesFromMap, initialDestTypeToValuesMap } from '../add-url-fields';
import { MAX_DEST_VALUES } from '../../../../../../../utils/constants';
import { hasDuplicatesValueOnArray } from '../../../../../../../utils/util';

interface AddUrlProps {
    apnName: string;
    listType: 'black' | 'white';
}

const AddUrl: React.FunctionComponent<AddUrlProps> = observer(({ apnName, listType }) => {
    const { useCaseStore } = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [destTypeToValuesMap, setDestTypeToValuesMap] = useState(initialDestTypeToValuesMap);
    const [hasErrors, setHasErrors] = useState(false);

    const totalItemsInDestTypeToValues = useMemo(() => {
        return createNewValuesFromMap(destTypeToValuesMap)
    }, [destTypeToValuesMap]);

    const combinedList = useMemo(() => {
        const listValues = useCaseStore.destValueByType(listType, apnName);
        return listValues.concat(totalItemsInDestTypeToValues);
    }, [totalItemsInDestTypeToValues]);

    const hasDuplicates = useMemo(() => {
        return hasDuplicatesValueOnArray(combinedList);
    }, [combinedList]);

    const openAddUrlModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setDestTypeToValuesMap(initialDestTypeToValuesMap);
        setIsModalOpen(false);
    };

    const maxEntriesExceeded = combinedList.length > MAX_DEST_VALUES;

    const handleAddUrl = () => {
        if (hasErrors || maxEntriesExceeded) return;

        useCaseStore.changeParamValueByApn(apnName, `${listType}_list_destinations`, combinedList);
        closeModal();
    };

    return (
        <>
            <Button className={"add-url-btn secondary-btn"} type="primary" onClick={openAddUrlModal}>+ URL/IP</Button>
            <CustomModal
                isOpen={isModalOpen}
                width="600px"
                title="Add Destination"
                okText="Add"
                onOk={handleAddUrl}
                okButtonProps={{ disabled: hasErrors || maxEntriesExceeded || !totalItemsInDestTypeToValues.length || hasDuplicates}}
                cancelButtonProps={{ className: 'secondary-btn' }}
                onCancel={closeModal}>
                <AddUrlFields
                    isDuplicate={hasDuplicates}
                    isMaxEntries={maxEntriesExceeded}
                    onValuesChange={(newDestTypeToValuesMap) => {
                        setDestTypeToValuesMap((prevState) => ({ ...prevState, ...newDestTypeToValuesMap }));
                    }}
                    setHasErrors={setHasErrors}
                />
            </CustomModal>
        </>
    );
});

export default AddUrl;