import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import './custom-modal.scss';

const CustomModal = ({
                         isOpen,
                         width,
                         title,
                         onOk,
                         onCancel,
                         okText = 'Ok',
                         cancelButtonProps = {},
                         okButtonProps = {},
                         showFooter = true,
                         customClassName = '',
                         children
                     }) => {
    return (
        <Modal
            open={isOpen}
            width={width}
            className={`custom-modal ${customClassName}`}
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            okType={"primary"}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            destroyOnClose={true}
            cancelButtonProps={cancelButtonProps}
            footer={showFooter ? undefined : null}
            okButtonProps={okButtonProps}>
            <div className={'modal-title'}>{title}</div>
            <div className={'info'}>{children}</div>
        </Modal>
    );
};

export default CustomModal;